<template>
    <div class="dashboard-contenedor">
        <div class="dashboard-menu">
            <div class="contenedor">
                <h1>Diputaciones</h1>
                 <p @click="goDipPE">Proceso Electoral</p>
                    <ul class="lista-indicadores" v-if="showIndicadores.diputaciones.procesoElectoral">
                        <li 
                            v-for="(indicador, index) in diputaciones.procesoElectoral" 
                            :key="index"
                            @click="goIndicador(indicador.nombrePath)"
                        >
                            {{ indicador.indicador }}
                        </li>
                    </ul>
                <p @click="goDipEC">Ejercicio del cargo</p>
                    <ul class="lista-indicadores" v-if="showIndicadores.diputaciones.ejercicioCargo">
                        <li 
                            v-for="(indicador, index) in diputaciones.ejercicioCargo" 
                            :key="index"
                            @click="goIndicador( indicador.nombrePath )"
                        >
                            {{ indicador.indicador }}
                        </li>
                    </ul>
            </div>
            <div class="contenedor">
                <h1>Municipios</h1>
                <p @click="goMunPE">Proceso Electoral</p>
                    <ul class="lista-indicadores" v-if="showIndicadores.municipios.procesoElectoral">
                         <li 
                            v-for="(indicador, index) in municipios.procesoElectoral" 
                            :key="index"
                            @click="goIndicador(indicador.nombrePath)"
                            >
                            {{ indicador.indicador }}
                        </li>
                        
                    </ul>
                <p @click="goMunEC">Ejercicio del cargo</p>
                    <ul class="lista-indicadores" v-if="showIndicadores.municipios.ejercicioCargo">
                         <li 
                            v-for="(indicador, index) in municipios.ejercicioCargo" 
                            :key="index"
                            @click="goIndicador(indicador.nombrePath)"
                            >
                            {{ indicador.indicador }}
                        </li>
                    </ul>
            </div>
            <button class="btn-cerrarSesion" @click="goLogin">Cerrar Sesión</button>
        </div>
        <div class="dashboard-textarea">
            <User/>
            <router-view/>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
    data() {
        return {
            municipios: {
                procesoElectoral:[],
                ejercicioCargo: []
            },
            diputaciones: {
                procesoElectoral: [],
                ejercicioCargo: []
            },
            showIndicadores: {
                municipios: {
                    procesoElectoral: false,
                    ejercicioCargo: false
                },
                diputaciones: {
                    procesoElectoral: false,
                    ejercicioCargo: false
                }
            }
        }
    },
    components: {
        User: defineAsyncComponent( ()=> import('../components/User.vue') )
    },
    methods:{
       
        goDipPE() {
            this.showIndicadores.diputaciones.procesoElectoral = !this.showIndicadores.diputaciones.procesoElectoral
        },
         goDipEC(){
            this.showIndicadores.diputaciones.ejercicioCargo = !this.showIndicadores.diputaciones.ejercicioCargo
        },
         goMunPE() {
            this.showIndicadores.municipios.procesoElectoral = !this.showIndicadores.municipios.procesoElectoral
        },
        goMunEC() {
            this.showIndicadores.municipios.ejercicioCargo = !this.showIndicadores.municipios.ejercicioCargo
        },
        getDataDiputacionesProcesoElectoral(){
                const url = '../db/diputaciones/diputaciones-procesoElectoral.json'
                fetch( url )
                    .then( res => res.json() )
                    .then( data =>  this.diputaciones.procesoElectoral = data )
        },
        getDataDiputacionesEjercicioCargo(){
            const url = '../db/diputaciones/diputaciones-ejercicioCargo.json'
            fetch( url )
                .then( res => res.json() )
                .then( data =>  this.diputaciones.ejercicioCargo = data )
        },
        getDataMunicipiosProcesoElectoral(){
            const url = '../db/municipios/municipios-procesoElectoral.json'
            fetch( url )
                .then( res => res.json() )
                .then( data =>  this.municipios.procesoElectoral = data )
        },
        getDataMunicipiosEjercicioCargo(){
            const url = '../db/municipios/municipios-ejercicioCargo.json'
            fetch( url )
                .then( res => res.json() )
                .then( data =>  this.municipios.ejercicioCargo = data )
        },
        goLogin() {
            this.$router.push({ name: 'user-login' })
        },
        goIndicador( data  ){
            this.$router.push({ name: `${ data }` })
        }
    },
    created() {
        this.getDataDiputacionesProcesoElectoral()
        this.getDataDiputacionesEjercicioCargo()
        this.getDataMunicipiosProcesoElectoral()
        this.getDataMunicipiosEjercicioCargo()
    }
}
</script>

<style lang="scss" scoped>

    .dashboard-contenedor{
        width:100%;
        height:calc(100vh - 60px);
        display:flex; 
    }
    .dashboard-menu{
        width:20%;
        height:calc(100vh - 60px);
        border-right:2px solid #392f5c;
        overflow: hidden;
        display: flex; align-items:center; justify-content:flex-start; flex-direction:column;

        & .contenedor{
            width:70%;
            margin:20px auto;
            
            h1{
                font-size:22px;
                font-weight: bold;
                margin:0;
            }
            p {
                font-size:18px;
                margin:0;
                padding: 0 0 0 10px;
                cursor:pointer;
            }
            p:hover{
                text-decoration: underline;
            }

            .lista-indicadores{
                width:100%;
                cursor:pointer;

                & li{
                    font-size: 14px;
                }

                & li:hover {
                    text-decoration:underline;
                }
                
            }
        }

        & .btn-cerrarSesion{
            width:16%;
            height:40px;
            position:absolute;
            margin:20px auto;
            bottom:0;
            background-color: #392f5c;
            border:none; 
            color: #fff;
            
        }
    }
    .dashboard-textarea{
        width:80%;
    }

</style>